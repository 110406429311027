import React from 'react';
import * as styles from './404.module.css';

import Button from '../components/Button';
import Container from '../components/Container';
import Layout from '../components/Layout';

const NotFoundPage = () => {
  return (
    <Layout disablePaddingBottom>
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>404 Erreur</h1>
          <h2>Page non trouvée</h2>
          <p>
            Euh oh, on dirait que la page que vous recherchez a déménagé ou n'existe plus.
          </p>
          <div className={styles.searchContainer}>
            <Button
              href={'/'}
              level={'primary'}
              title="Retour vers la page d'accueil"
            >
              Revenir vers l'accueil
            </Button>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
